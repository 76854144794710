import styled from "styled-components";
import { Link } from "react-router-dom";

export const PlaylistInfo = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

export const PlaylistOwner = styled(Link)`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;
